import React, {
  useEffect,
  useState,
} from 'react';

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  ConnectionProvider,
  useWallet,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
} from '@solana/wallet-adapter-wallets';

import MyWallet from './MyWallet';
import TokenGrid from './TokenGrid';

function App() {
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = React.useMemo(() => "https://mainnet.helius-rpc.com/?api-key=56761983-0ed6-466e-be62-ffe71c83a19c", [network]);
  const wallets = React.useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getLedgerWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [network]
  );
  const wallet = useWallet();
  const [tokenData, setTokenData] = useState([]);
  const [publicKey, setPublicKey] = useState(null); // State to store the public key

  useEffect(() => {
    if (publicKey) {
      fetchTokenData(publicKey)
        .then(data => {
          setTokenData(data);
        })
        .catch(error => {
          console.error('Error fetching token data:', error);
        });
    }
  }, [publicKey]); // Effect runs whenever publicKey changes


  const fetchTokenData = async (walletAddress: string) => {
    try {
      const url = `https://mainnet.helius-rpc.com/?api-key=56761983-0ed6-466e-be62-ffe71c83a19c`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jsonrpc: "2.0",
          id: "my-id",
          method: "searchAssets",
          params: {
            ownerAddress: walletAddress,
            tokenType: "fungible",
            displayOptions: {
              showFungible: true,
              showNativeBalance: true,
            },
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { result } = await response.json();
      console.log("result:", result);
      return result.items;
    } catch (error) {
      console.error('Error in fetchTokenData:', error);
    }
  };

  const onWalletConnect = (newPublicKey) => {
    console.log('Wallet connected in App. PublicKey:', newPublicKey);
  
    // Reset publicKey state before setting the new value
    setPublicKey(null);
    setTimeout(() => setPublicKey(newPublicKey), 0);
  };

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets}>
        <div className="App">
          <header className="App-header">
            <div style={{ display: 'flex', alignItems: 'center' }}> {/* Group logo and title together */}
              <img src="/donkonsol.png" alt="DONK LOGO" className="App-logo" />
              <h1 className="App-title">DUMP TO DONK</h1>
            </div>
            <MyWallet onWalletConnect={onWalletConnect} />
          </header>
          

          <TokenGrid initialTokenData={tokenData} />
        </div>
      </WalletProvider>
    </ConnectionProvider>
  );
  
}

export default App;
