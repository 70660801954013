import React, { useEffect, useRef, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton,
} from '@solana/wallet-adapter-react-ui';

interface MyWalletProps {
  onWalletConnect: (publicKey: string) => void;
}

const MyWallet: React.FC<MyWalletProps> = ({ onWalletConnect }) => {
    const wallet = useWallet();
    const previousPublicKey = useRef(wallet.publicKey);
    const [wasConnected, setWasConnected] = useState(wallet.connected);

    useEffect(() => {
        const walletAddress = wallet.publicKey?.toBase58();
        const previousWalletAddress = previousPublicKey.current?.toBase58();

        // Call onWalletConnect only if:
        // - The wallet was not previously connected and is now connected, or
        // - The wallet remains connected and the public key has changed
        if ((wallet.connected && !wasConnected) || 
            (wallet.connected && walletAddress !== previousWalletAddress)) {
            onWalletConnect(walletAddress);
            previousPublicKey.current = wallet.publicKey;
        }

        // Update wasConnected state
        setWasConnected(wallet.connected);
    }, [wallet.connected, wallet.publicKey, onWalletConnect, wasConnected]);

    return (
        <div className="wallet-container" style={{ position: 'absolute', top: 0, right: 0, display: 'flex', alignItems: 'center', gap: '10px', padding: '10px' }}>
            <WalletModalProvider>
                <WalletMultiButton />
            </WalletModalProvider>
            {wallet.connected && <WalletDisconnectButton />}
        </div>
    );
};

export default MyWallet;
